/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import GuestNavBar from "../components/GuestNavBar";
import PrimarySearchAppBar from "../components/AuthorizedUserHomepageNavbar";
import {
  Box,
  Typography,
} from "@material-ui/core";
import clsx from 'clsx';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { sendUserData } from "../actions/form.js";
import { loadUser } from "../actions/auth.js";
import { call_save, reset_go_to_next } from "../actions/form.js";
import DashboardWizardSteps from "../components/DashboardWizardSteps";
import Spinner from "../assets/Spinner.gif";
import Loader from "../components/Spinner";
import { isSafari } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  stepper: {
    width: "90%",
    margin: theme.spacing(8, "auto", 4),
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "0",
    marginBottom: "0",
    [theme.breakpoints.down("768")]: {
      flexWrap: "wrap",
      overflow: "auto"
    },
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  radioLabel: {
    "& span": {
      fontSize: "13px!important",
      color: "#1b1f23",
    },
  },
  wizardContainer: {
    margin: theme.spacing(2, "auto", 2),
    minWidth: '50%',
    paddingBottom: "0",
    marginBottom: "0",
  },
  form: {
    // margin: 'auto',
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    // margin: theme.spacing(2, 'auto', 4),
    textAlign: "center",
    paddingBottom: "0",
    marginBottom: "0",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formLabelPetPrefs: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    margin: theme.spacing(1, 0),
  },
  formLabelPetExperience: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    lineHeight: "2.5em",
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroupLabelPetPrefs: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  imageSelector: {
    fontSize: "8rem",
    paddingLeft: theme.spacing(0),
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2)
  },
  fieldSetRadio: {
    marginLeft: theme.spacing(1),
  },
  adornment: {
    "& p": {
      fontSize: "13px",
    },
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#FF3D00!important",
    marginRight: "auto",
    height: "30px",
    width: "180"
  },
  sized: {
    height: '30px',
    width: '220px'
  }
}));

function getSteps() {
  return [
    "Profile Info",
    "Address Info",
    "Contact Info",
    "Services Info",
    "Pet Preferences",
    "Experience",
    "Background Check",
  ];
}

function WizardPetProvider(props) {
  const classes = useStyles();


  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    //return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (props.form.user_editing === true) {
      props.call_save();
    } else {
      // user didn't edit any data, so just move to next step
      console.log(props.form.user_missing_info)
      if(!props.form.user_missing_info){

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        // code for saving recent stage....

        const current_stage = localStorage.getItem(
          `stage_value_${props.user.id}`
        );
        //console.log(current_stage)
        if (current_stage) {
          var next_stage = parseInt(current_stage) + 1;
          localStorage.setItem(`stage_value_${props.user.id}`, next_stage);
        }
        if (current_stage === "6") {
          var stage = 0;
          var stage_ = parseInt(stage);
          localStorage.setItem("stage_value", stage_);
        }
      }
    }
  };

  React.useEffect(() => {
    if (props.form.go_to_next === true) {
      // console.log("go now")
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);

      // code for saving recent stage....

      const current_stage = localStorage.getItem(
        `stage_value_${props.user.id}`
      );
      //console.log(current_stage)
      if (current_stage) {
        var next_stage = parseInt(current_stage) + 1;
        localStorage.setItem(`stage_value_${props.user.id}`, next_stage);
      }
      if (current_stage === "6") {
        var stage = 0;
        var stage_ = parseInt(stage);
        localStorage.setItem("stage_value", stage_);
      }
      props.reset_go_to_next(); // Resetting go_to_next for next redirect
    }
  }, [props.form.go_to_next]);

  const handleBack = () => {
    props.loadUser();
    props.loadUser();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // code for saving recent stage....
    const current_stage = localStorage.getItem(`stage_value_${props.user.id}`);
    if (current_stage) {
      var next_stage = parseInt(current_stage) - 1;
      localStorage.setItem(`stage_value_${props.user.id}`, next_stage);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const remove_id = () => {
    localStorage.removeItem(`stage_value_${props.user.id}`);
  };

  React.useEffect(() => {
    props.loadUser();
  }, []);

  React.useEffect(() => {
    const current_stage = localStorage.getItem(`stage_value_${props.user.id}`);
    // const stage         = localStorage.getItem(`stage_value_${props.user.id}`);
    // console.log(current_stage)
    if (current_stage === null || current_stage === undefined) {
      var stage = 0;
      var stage_ = parseInt(stage);
      // localStorage.setItem("stage_value", stage_);
      localStorage.setItem(`stage_value_${props.user.id}`, stage_);
    }
  }, []);

  return (
    <Box className={classes.root}>
      <div>{props.auth.isAuthenticated ? <PrimarySearchAppBar /> : ""}</div>
      <div>{!props.auth.token ? <GuestNavBar /> : ""}</div>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Typography variant="h5" className={classes.formHeader}>
        Your account information
      </Typography>
      <Container maxWidth="sm" className={classes.wizardContainer}>
        <form noValidate className={classes.form} autoComplete="new-password">
          <DashboardWizardSteps
            step={activeStep}
            setActiveStep={setActiveStep}
          />
          {/*{getStepContent(activeStep)}  */}
        </form>

        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Link to="/dashboard">
                <Button
                  id="finishLink"
                  variant="contained"
                  color="primary"
                  onClick={remove_id}
                  className={classes.buttonNext}
                >
                  Finish
                </Button>
              </Link>
            </div>
          ) : (
            <div>
              <div className={classes.buttonSection}>
                <Button
                  disabled={activeStep === 0}
                  onClick={() => {
                    handleBack();
                    // window.location.reload(false);
                  }}
                  className={classes.buttonBack}
                >
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Link to="/dashboard" style={{ textDecoration: "none" }}>
                    <Button
                      id="finishLink2"
                      variant="contained"
                      color="primary"
                      className={classes.buttonNext}
                    >
                      Finish
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    id="saveAndContinue"
                    onClick={handleNext}
                    // className={classes.buttonNext}

                    // className={ 
                    //   isSafari ? clsx(classes.buttonNext, classes.sized):
                    //   classes.buttonNext
                    //  }>
                    className={
                      isSafari ? clsx(classes.buttonNext, classes.sized) :
                        classes.buttonNext
                    }>
                    {props.form.signal_save ? <Loader /> : "Save & Continue"}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
      <Box
        color="white"
        p={2}
        position="absolute"
        top={460}
        left="75%"
        zIndex="tooltip"
      >
        {props.form.isLoading && (
          <img src={Spinner} alt="" height="35px" width="35px" />
        )}
      </Box>{" "}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  all_services: state.services.admin_services,
});
export default connect(mapStateToProps, {
  sendUserData,
  loadUser,
  call_save,
  reset_go_to_next,
})(WizardPetProvider);

// function BackgroundCheck(props){
//     const classes = useStyles();

//     return(
//         <div>
//             <FormGroup className={classes.formGroup}>
//                 <FormLabel component="label" className={classes.formGroupLabel}>Background Check</FormLabel>
//                 <Grid container spacing={2}>
//                     <Typography className={classes.explanationText}>
//                     Pet owners are more likely to work with you if a background
//                     check has been conducted on you. We use Checkr: a third
//                     party for this service.
//                     {/* We shall add an icon and a link to Checkr here  */}
//                     </Typography>
//                     <Button
//                     variant="contained"
//                     color="primary"
//                     className={classes.addRowButton}
//                     disabled={buttonClicked}
//                     onClick={handleClick}
//                     startIcon={<AddCircleRoundedIcon />}
//                     >
//                     {buttonClicked
//                         ? "FIll in the following Info"
//                         : "Start Running Background Check"}
//                     </Button>
//                 </Grid>
//             </FormGroup>
//         </div>
//     )
// }
