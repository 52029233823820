/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { screening } from "../actions/auth.js";

import { loadUser } from "../actions/auth.js";
import {
  sendUserData,
  start_check,
  get_payment_method,
  clear_error,
} from "../actions/form";
import Icon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  imageSelector: {
    fontSize: "8rem",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  explanationText: {
    height: "auto",
    display: "flex",
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "column",
    fontSize: "13px",
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  selectEmpty: {
    float: "left",
    width: "100%",
    borderRadius: "10px",
    height: "auto!important",
    "& > label": {
      paddingLeft: theme.spacing(1),
    },
  },
  addRowButton: {
    marginTop: theme.spacing(2),
  },
  selectFormControl: {
    width: "100%",
  },
  serviceTitle: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  dropzone: {
    marginTop: theme.spacing(4),
  },
  checkText: {
    marginTop: theme.spacing(1),
    color: "black",
  },
  submit: {
    margin: theme.spacing(4, 0, 1),
    height: "2.5rem",
    backgroundColor: "#FF3D00!important",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 0),
    },
    //float: 'right',
  },
  submit_: {
    margin: theme.spacing(4, 0, 1),
    height: "2.5rem",
    backgroundColor: "green",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 0),
    },
    //float: 'right',
  },
  submitt_: {
    margin: theme.spacing(4, 0, 1),
    height: "2.5rem",
    backgroundColor: "red",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 0),
    },
    //float: 'right',
  },
  gridSubsection: {
    marginBottom: theme.spacing(1),
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formArea: {
    marginTop: theme.spacing(2),
  },
  paymentDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
}));

function BackgroundCheckSection(props) {
  const classes = useStyles();
  // const [buttonClicked, isButtonClicked] = useState(false);
  // const [showSSN, setShowSSN] = useState(false);
  const [status, setStatus] = useState(true);
  // const check_bool = localStorage.getItem(`background_status_${props.user.id}`);

  // const handleClickShowSSN = () => setShowSSN(!showSSN);

  // let user_id;
  // if (props.user !== null && props.user !== undefined) {
  //   user_id = props.user.id;
  // }

  // function handleClick() {
  //   isButtonClicked(true);
  // }

  React.useEffect(() => {
    props.loadUser();
    props.loadUser();
  }, []);

  //  React.useEffect(() => {
  //     //console.log("hereeeeeeeeeee")
  //     console.log(check_bool)
  // }, []);

  // payments zone
  const [open] = React.useState(false);
  // const [scroll, setScroll] = React.useState("paper");
  // pop up
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  // const handleSubmit = (e) => {
  //   console.log(props.user.id);
  //   if (props.user.id) {
  //     props.start_check(props.user.id);
  //   }
  // };

  React.useEffect(() => {
    props.get_payment_method();
  }, []);

  // let results = null;
  // if (props.payment_methods !== undefined && props.payment_methods !== null) {
  //   results = props.payment_methods;
  // }

  // const fetch_again = (e) => {
  //   props.get_payment_method();
  //   props.get_payment_method();
  // };

  // const handleClose = () => {
  //   setOpen(false);

  //   // clear state so that the notification in the pop up component doesn't stick on the UI
  //   // if(error){}  // its also good practise to clear that exact error, but here we are clearing the entire error state
  //   props.clear_error();
  // };

  React.useEffect(() => {
    if (props.form.isLoading === true) {
      props.get_payment_method();
      props.loadUser();
    }
  }, [props.form.isLoading]);

  React.useEffect(() => {
    // console.log(props.user.first_name)
    if (
      props.user.first_name !== "" &&
      props.user.last_name !== "" &&
      props.user.ssn !== "" &&
      props.user.date_of_birth !== "" &&
      props.user.address_line_1 !== "" &&
      props.user.city !== "" &&
      props.user.state !== "" &&
      props.user.country !== "" &&
      props.user.zipcode !== ""
    ) {
      setStatus(false);
      // console.log("not disabled")
    } else if (
      props.user.first_name === "" ||
      props.user.last_name === "" ||
      props.user.ssn === "" ||
      props.user.date_of_birth === "" ||
      props.user.address_line_1 === "" ||
      props.user.city === "" ||
      props.user.state === "" ||
      props.user.country === "" ||
      props.user.zipcode === ""
    ) {
      setStatus(true);
      // console.log("disabled")
    }
  }, [
    props.user.first_name,
    props.user.last_name,
    props.user.ssn,
    props.user.date_of_birth,
    props.user.address_line_1,
    props.user.city,
    props.user.state,
    props.user.country,
    props.user.zipcode,
  ]);

  console.log(props.user.background_check_status)
  console.log(props.user.background_check_complete)

  // const [show_not, setShow_not] = useState("");
  // const [open_, setOpen_] = React.useState(true);

  // const show_error = (value) => {
  //   // console.log(value);
  //   // console.log(show_not);
  //   setShow_not(value);
  // };
  // const set_notification = () => {
  //   setOpen_(false);
  //   setShow_not("");
  // };

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.explanationText}>
                  Pet owners are more likely to work with you if you have
                  completed a background check.
                  {/* We shall add an icon and a link to Checkr here  */}
                </Typography>
                <Typography className={classes.explanationText}>
                  We use Checkr a third party for this service and they charge
                  a fee of 35 dollars.
                </Typography>

                {props.user.background_check_status === "pending" &&
                props.user.background_check_complete === false ? (
                  <Button
                    // type="submit"
                    disabled={status}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => (window.location.href = "/wizard")}
                  >
                    {"Start Background check"}
                  </Button>
                ) : (
                  ""
                )}

                {props.user.background_check_status === "started" &&
                props.user.background_check_complete === false ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    //onClick={handleSubmit}
                  >
                    {"Background check pending..."}
                  </Button>
                ) : (
                  ""
                )}

                {(props.user.background_check_status === "passed" || props.user.background_check_status === "Passed") &&
                props.user.background_check_complete === true ? (
                  <Button
                    // type="submit"
                    // disabled={true}
                    variant="contained"
                    color="primary"
                    className={classes.submit_}
                    // onClick={(e) => (window.location.href = "/wizard")}
                    endIcon={<Icon></Icon>}
                    //onClick={handleClickOpen('paper')}
                  >
                    {"Background check cleared"}
                  </Button>
                ) : (
                  ""
                )}
                {(props.user.background_check_status === "failed" || props.user.background_check_status === "Failed") &&
                props.user.background_check_complete === true ? (
                  <Button
                    // type="submit"
                    // disabled={true}
                    variant="contained"
                    color="primary"
                    className={classes.submitt_}
                    // onClick={(e) => (window.location.href = "/wizard")}
                    endIcon={<CancelIcon></CancelIcon>}
                    //onClick={handleClickOpen('paper')}
                  >
                    {"Background check not cleared"}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  screeningResults: state.screening.results,
  beingProcessed: state.screening.beingProcessed,
  user: state.auth.user,
  payment_methods: state.services.payment_methods,
  form: state.forms,
});

export default connect(mapStateToProps, {
  screening,
  loadUser,
  sendUserData,
  start_check,
  get_payment_method,
  clear_error,
})(BackgroundCheckSection);
