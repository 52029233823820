import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Button, Box } from "@material-ui/core";
import Chat from "./Chat";

const Messaging = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSend = () => {
        // Logic to send the message
        console.log("Sent message:", message);
        setMessage("");
    };

    return (
        <div>
            <Button
                size="small"
                onClick={handleOpen}
                variant="outlined"
                color="primary"
            >
                <span>{" "} Send message </span>
            </Button>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    <span
                        style={{
                            fontFamily: "Dosis",
                            fontWeight: "bold", 
                            fontSize: "2rem"
                        }}>Chat
                    </span>
                </DialogTitle>
                <DialogContent>
                    <Chat />
                </DialogContent>
            </Dialog>

        </div>
    );
}

export default Messaging
