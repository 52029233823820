import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { InputText } from "./InputText.js";
import { MessageLeft, MessageRight } from "./MessageComponents.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100vw",
      height: "auto",
      maxWidth: "500px",
      maxHeight: "700px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative"
    },
    paper2: {
      width: "auto",
      height: "auto",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative"
    },
    container: {
      width: "auto",
    //   height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    messagesBody: {
      width: "99%",
      padding: '5px',
      margin: 10,
    //   overflowY: "scroll",
      height: "calc( 100% - 80px )"
    }
  })
);

export default function Chat() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Paper className={classes.paper} zDepth={1}>
        <div id="style-1" className={classes.messagesBody}>
          <MessageLeft
            message="Please come and pick my dog"
            // timestamp="10/02/2024 4:30PM"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="MJ"
            avatarDisp={true}
          />
          <MessageLeft
            message="Hello Good afternoon"
            // timestamp="10/02/2024 4:30PM"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="MJ"
            avatarDisp={false}
          />
          <MessageRight
            message="Hi Good evening"
            // timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="Me"
            avatarDisp={true}
          />
          <MessageRight
            message="I will be coming today to walk the dog"
            // timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="Me"
            avatarDisp={false}
          />
          <MessageLeft
            message="Please come and pick my dog"
            // timestamp="10/02/2024 4:30PM"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="MJ"
            avatarDisp={true}
          />
          <MessageLeft
            message="Hello Good afternoon"
            // timestamp="10/02/2024 4:30PM"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="MJ"
            avatarDisp={false}
          />
          <MessageRight
            message="Hi Good evening"
            // timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="Me"
            avatarDisp={true}
          />
           <MessageLeft
            message="Hello Good afternoon"
            // timestamp="10/02/2024 4:30PM"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="MJ"
            avatarDisp={false}
          />
        </div>
        <InputText />
      </Paper>
    </div>
  );
}
