import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ListItemAvatar } from '@material-ui/core';
import { loadUser } from '../actions/auth.js';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(4)
  },
  inline: {
    display: 'inline'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginTop: '64px',
    backgroundColor: '#fafafa',
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: '64px'
  },
  content: {
    flexGrow: 1
  },
  ul: {
    width: '100%'
  }
}));

function NotificationsDropdownMenu(props) {
  const classes = useStyles();
  // const theme = useTheme();
  // const [mobileOpen, setMobileOpen] = React.useState(false);

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <Paper className={classes.root}>
            <MenuList className={classes.ul}>
              <MenuItem>
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" />
                </ListItemAvatar>
                <ListItemText
                  primary="Remy sharp set an Appointment"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        noWrap
                      >
                        Ali Connors
                      </Typography>
                      <br />
                      {` Time: 0200pm; Date: 05/12/2020`}
                    </React.Fragment>
                  }
                />
              </MenuItem>
              <Divider variant="inset" component="li" />
              <MenuItem>
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" />
                </ListItemAvatar>
                <ListItemText
                  primary="Jackie Chan made a payment"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        noWrap
                      >
                        A payment of 35 dollars was made to you
                      </Typography>
                      <br />
                      {` Time: 0200pm; Date: 05/12/2020`}
                    </React.Fragment>
                  }
                />
              </MenuItem>
            </MenuList>
          </Paper>
        </main>
      </div>
    </div>
  );
}

NotificationsDropdownMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loadUser })(NotificationsDropdownMenu);
