import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) =>
    createStyles({
        wrapForm: {
            display: "flex",
            alignItems: "center",
            width: "98%",
            margin: `${theme.spacing(1)} auto`,
            background: "#f5f5f5",
            borderRadius: "10px",
            padding: "5px 10px",
            marginBottom: "5px",
            border: "1px solid #ddd",
            fontFamily: "Dosis"
        },
        wrapText: {
            flex: 1,
            fontFamily: "Dosis",
            border: "none",
            "& .MuiOutlinedInput-root": {
                borderRadius: "25px",
                backgroundColor: "transparent",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
            },
        },
        button: {
            minWidth: "40px",
            height: "40px",
            fontFamily: "Dosis",
            borderRadius: "50%",
            backgroundColor: "#007BFF",
            color: "white",
            padding: "5px",
            marginLeft: "5px",
            "&:hover": {
                backgroundColor: "#0056b3",
            },
        },
        sendIcon: {
            fontSize: "18px",
        },
        placeholderText: {
            fontFamily: "Dosis, sans-serif",
            
            fontFamily: "Dosis",
            fontWeight: 'bold',
            fontSize: '1rem',
        },
    })
);

export const InputText = () => {
    const classes = useStyles();

    return (
        <>
            <form className={classes.wrapForm} noValidate autoComplete="off">
                <TextField
                    placeholder={"Message here..."}
                    variant="outlined"
                    className={classes.wrapText}
                    InputProps={{
                        classes: {
                            input: classes.placeholderText,
                        },
                    }}
                />
                <Button variant="contained" className={classes.button}>
                    <SendIcon className={classes.sendIcon} />
                </Button>
            </form>
        </>
    );
};
