/* eslint-disable array-callback-return */
import axios from 'axios';
import FormData from 'form-data';
import { returnError } from './errors';
import { createMessage } from './messages';

import {
  FORM_LOADING,
  FORM_LOADED,
  SERVICE_LOADED,
  SERVICE_LOADING,
  PETS_LOADED,
  PETS_LOADING,
  SENDING_CERTIFICATE,
  CERTIFICATE_LOADED,
  CERTIFICATE_LOADING,
  SENT_CERTIFICATE,
  ALL_SERVICE_LOADED,
  CREATE_SERVICE,
  USER_CREATE_PET,
  SERVICE_DELETED,
  GOT_PET_TYPES,
  PET_DELETED,
  SENDING_DATA,
  ALL_RATES_LOADED,
  CARD_CREATED_FAIL,
  CARD_CREATED_SUCCESS,
  CARD_LOADING,
  CREATE_PAYMENT_METHOD,
  GET_PAYMENT_METHOD,
  PAYMENT_METHOD_DELETED,
  CLEAR_STATE_,
  FETCH_,
  FETCH_DONE_,
  CREATE,
  GETTING_DONE,
  CLEAR_STATE_PET,
  GET_AVAILABILITY,
  GET_BREEDS,
  GET_BREEDS_CAT,
  START_CHECK,
  CLEAR_STATE_PAYMENT,
  BACKGROUNDCHECK_PAYMENT,
  ENABLE_BUTTON,
  PAYMENT_SCREENING_ERROR,
  CLEAR_BACKGROUNDCHECK_ERROR,
  CLEAR_AVAILABILITY,
  CREATE_AVAILABILITY_INSTANCE,
  SAVE_COLLECTIVE_DATA,
  FORM_LOADING_DATA,
  FORM_LOADED_DATA,
  GO_TO_NEXT,
  RESET_GO_TO_NEXT,
  USER_EDITING,
  RESET_USER_EDITING,
  GO_TO_WIZARD_SOCIAL,
  RESET_SAVE_SIGNAL,
  USER_MISSING_INFO,
  USER_MISSING_INFO_TURN_OFF,
  GET_AVAILABILITY_DATA,
  PHOTOS_LOADED,
  PHOTO_LOADING,
  PHOTO_UPLOADED,
  PHOTOS_DELETED,
  CLEAR_AVAILABLE,
  FORM_LOADING_CREATE,
  GET_ADDITIONAL_RATES_TYPES,
  CREATE_ADDITIONAL,
  CREATED_ADDITIONAL,
  ADDITIONAL_RATES_LOADED,
  FAQS_LOADING,
  FAQS_LOADED,
  MAKE_BOOKING_FIRST_SIGNAL,
  MAKE_BOOKING_FIRST_SIGNAL_RESET,
  FORM_PET_UPDATE_LOADING,
  PROVIDER_IMAGES_LOADING,
  PROVIDER_IMAGES_FETECHED,
  PROVIDER_IMAGES_FAILED,
  DELETE_PHOTO_LOADING,
  SET_TO_NULL_NEXT_APPOINTMENTS,
  MAKE_PRIMARY_LOADING,
  MAKE_PRIMARY,
  MAKE_PRIMARY_FAILED,
  ACCOUNT_DEACTIVATED,

} from './types';
import { tokenConfig } from './auth';
import {  createMessage_status } from './messages';

export const backgroundcheck_payment =
  (service, payment_method, sender, status) => (dispatch, getState) => {
    dispatch({ type: FORM_LOADING });
    // console.log("started")
    const user = sender;
    const body = JSON.stringify({
      service,
      payment_method,
      sender,
      status
    });
    console.log(body);

    const body_screening = JSON.stringify({
      user
    });
    console.log(body_screening);

    axios
      .post(`${process.env.REACT_APP_API_URL}payments/`, body, tokenConfig(getState))
      .then((res) => {
        // console.log(res.data)
        if (res.data) {
          dispatch({
            type: BACKGROUNDCHECK_PAYMENT,
            payload: res.data
          });
          dispatch({ type: FORM_LOADING });
          axios
            .post(
              `${process.env.REACT_APP_API_URL}screenings/`,
              body_screening,
              tokenConfig(getState)
            )
            .then((res) => {
              if (res.data) {
                // console.log(res.data.user);
                const status = 'true';
                localStorage.setItem(`background_status_${res.data.user}`, status);
                dispatch(createMessage('Background check has been initiated!'));
                dispatch({ type: ENABLE_BUTTON });
                // Next step is to use local storage or the backend to turn the status to "BGC pending"
                // ===================================================================================
                // dispatch({
                //   type: PAYMENT_SCREENING,
                //   payload: res.data,
                // });
              }
              dispatch({ type: FORM_LOADED });
            })
            .catch((err) => {
              // console.log("Up here")
              dispatch({ type: ENABLE_BUTTON });

              if (err) {
                // eslint-disable-next-line no-unused-vars
                const list = [];
                if (err?.response?.data?.user?.length === 1) {
                  // eslint-disable-next-line no-unused-vars
                  const check = err.response.data.user.filter((error) => {
                    if (error.message) {
                      dispatch({
                        type: PAYMENT_SCREENING_ERROR,
                        payload: { user: error.message },
                        status: err.response.status
                      });
                      // console.log(error.message)
                    }
                  });
                } 

                if(err?.response?.data){
                  dispatch({
                    type: PAYMENT_SCREENING_ERROR,
                    payload: { user: err?.response?.data[0] },
                    status: err.response.status
                  });
                  dispatch({ type: FORM_LOADED });
                }

              }
              dispatch({ type: FORM_LOADED });
            });
        }
      })
      // error on payment (really rear because all the data is derived from IDs in the user data)
      .catch((err) => {
        console.log(err);
        // dispatch(returnError(err.response.data, null));
        dispatch({
          type: PAYMENT_SCREENING_ERROR,
          payload: { user: err.response.data },
          status: null
        });
        dispatch({ type: FORM_LOADED });
      });
  };


export const createBackgroundCheck = (user, payment_method) => (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
  
  const url = `${process.env.REACT_APP_API_URL}screenings/`
  const screening = JSON.stringify({ user, payment_method });
  
  axios.post(url, screening, tokenConfig(getState))
  .then((res) => {
    if (res.data) {
        const status = 'true';
        localStorage.setItem(`background_status_${res.data.user}`, status);
        dispatch(createMessage('Background check has been initiated!'));
        dispatch({ type: ENABLE_BUTTON });
    }
    dispatch({ type: FORM_LOADED });
  })
  .catch((err) => {
    dispatch({ type: ENABLE_BUTTON });
  
    if (err) {
      // const list = [];
        
      if (err?.response?.data?.user?.length === 1) {
        const check = err.response.data.user.filter((error) => {
          if (error.message) {
            dispatch({
              type: PAYMENT_SCREENING_ERROR,
              payload: { user: error.message },
              status: err.response.status
            });
          }
        });
        console.log(check)
      } 
  
      if(err?.response?.data){
        dispatch({
          type: PAYMENT_SCREENING_ERROR,
          payload: { user: err?.response?.data[0] },
          status: err.response.status
        });
          
        dispatch({ type: FORM_LOADED });
      }
    }
  
    dispatch({ type: FORM_LOADED });
  });
};

export const clear_error = () => (dispatch) => {
  dispatch({
    type: CLEAR_BACKGROUNDCHECK_ERROR
  });
};

export const make_booking_first_signal = () => (dispatch) => {
  dispatch({
    type: MAKE_BOOKING_FIRST_SIGNAL
  });
};

export const make_booking_first_signal_reset = () => (dispatch) => {
  dispatch({
    type: MAKE_BOOKING_FIRST_SIGNAL_RESET
  });
};

export const delete_availability_instance = (id) => (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
  console.log('called');
  axios
    .delete(`${process.env.REACT_APP_API_URL}appointments/${id}/`, tokenConfig(getState))
    .then(() => {
      console.log('Deleted');
      dispatch({ type: FORM_LOADED });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FORM_LOADED });
    });
};

export const get_faqs = () => (dispatch, getState) => {
  
  dispatch({ type: FAQS_LOADING });
  axios
    .get(`${process.env.REACT_APP_API_URL}faqs/`)
    .then((res) => {
      console.log(res)
      dispatch({ 
          type: FAQS_LOADED,
          payload: res.data.results
       });
    })
    .catch((err) => {
      dispatch({ 
          type: FAQS_LOADED,
          payload: null
       });
    });
};

export const create_availability_instance =
  (start_date, end_date, start_time, end_time, notes, created_by, recurring, interval) =>
  async (dispatch, getState) => {
    dispatch({ type: FORM_LOADING });

    // const age = parseFloat(age_);
    const body = JSON.stringify({
      start_date,
      end_date,
      start_time,
      end_time,
      notes,
      recurring,
      interval,
      created_by
    });
    console.log(body);

    await axios
      .post(`${process.env.REACT_APP_API_URL}appointments/`, body, tokenConfig(getState))
      .then((res) => {
        if (res.data) {
          dispatch({
            type: CREATE_AVAILABILITY_INSTANCE,
            payload: res.data
          });
        }
        dispatch({ type: FORM_LOADED });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        } else {
          console.log(err);
        }
      });
  };

export const edit_availability_instance =
  (start_date, end_date, start_time, end_time, notes, created_by, id, recurring, interval) =>
  (dispatch, getState) => {
    dispatch({ type: FORM_LOADING });

    // const age = parseFloat(age_);
    const body = JSON.stringify({
      start_date,
      end_date,
      start_time,
      end_time,
      notes,
      recurring,
      interval,
      created_by
    });
    console.log(body);
    axios
      .patch(`${process.env.REACT_APP_API_URL}appointments/${id}/`, body, tokenConfig(getState))
      .then((res) => {
        if (res.data) {
          dispatch({
            type: CREATE_AVAILABILITY_INSTANCE,
            payload: res.data
          });
        }
        dispatch({ type: FORM_LOADED });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        } else {
          console.log(err);
        }
      });
  };

export const clear_aval = () => (dispatch) => {
  dispatch({
    type: CLEAR_AVAILABILITY
  });
};

export const clear_available = () => (dispatch) => {
  dispatch({
    type: CLEAR_AVAILABLE
  });
};

export const get_availability = (url) => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
    if(url){
     await axios
      .get(`${url}`, tokenConfig(getState))
      .then((res) => {
        console.log('GET_AVAILABILITY Initial..')
        dispatch({
          type: GET_AVAILABILITY,
          payload: res.data
        });
        dispatch({ type: FORM_LOADED });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        } else {
          console.log(err);
        }
      });

    }else{
     await axios
      .get(`${process.env.REACT_APP_API_URL}appointments/`, tokenConfig(getState))
      .then((res) => {
        console.log('GET_AVAILABILITY paginated..')
        dispatch({
          type: GET_AVAILABILITY,
          payload: res.data
        });
        dispatch({ type: FORM_LOADED });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        } else {
          console.log(err);
        }
      });

    }
};

export const setToNull = () => (dispatch) => {
  dispatch({
    type: SET_TO_NULL_NEXT_APPOINTMENTS
  });
};

export const get_availability_data = (id, url) => async (dispatch, getState) => {
  // dispatch({ type: FORM_LOADING });
  if(url){
    
    await axios
    .get(`${url}`, tokenConfig(getState))
    .then((res) => {
      console.log("res.data")
      console.log(res.data)
      dispatch({
        type: GET_AVAILABILITY_DATA,
        payload: res.data
      });
      // dispatch({ type: FORM_LOADED });
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      } else {
        console.log(err);
      }
    });

  }else{

    await axios
    .get(`${process.env.REACT_APP_API_URL}users/${id}/appointments/`, tokenConfig(getState))
    .then((res) => {
      console.log("res.data")
      console.log(res.data)
      dispatch({
        type: GET_AVAILABILITY_DATA,
        payload: res.data
      });
      // dispatch({ type: FORM_LOADED });
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      } else {
        console.log(err);
      }
    });

  }
};


export const get_images = (id) => async (dispatch, getState) => {
  dispatch({ type: PROVIDER_IMAGES_LOADING });
  await axios.get(`${process.env.REACT_APP_API_URL}users/${id}/photos`, tokenConfig(getState))
      .then((res) => {
        // console.log(res.data);
        dispatch({ 
           type: PROVIDER_IMAGES_FETECHED,  
           payload: res.data.results
        });
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({ type: PROVIDER_IMAGES_FAILED });
   });
};

export const start_check = (user) => (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
  const body = JSON.stringify({
    user
  });
  console.log(body);
  axios
    .post(`${process.env.REACT_APP_API_URL}screenings/`, body, tokenConfig(getState))
    .then((res) => {
      if (res) {
        const status = true;
        localStorage.setItem('check_status', status);
        dispatch({
          type: START_CHECK
        });
      }
      dispatch({ type: FORM_LOADED });
    })
    .catch((err) => {
      dispatch({ type: FORM_LOADED });
      if (err) {
        console.log(err);
      } else {
        console.log(err);
      }
    });
};

export const call_save = () => (dispatch) => {
  dispatch({
    type: SAVE_COLLECTIVE_DATA
  });
};

export const user_editing = () => (dispatch) => {
  dispatch({
    type: USER_EDITING
  });
};

export const reset_user_editing = () => (dispatch) => {
  dispatch({
    type: RESET_USER_EDITING
  });
};

export const reset_save_signal = () => (dispatch) => {
  dispatch({
    type: RESET_SAVE_SIGNAL
  });
};

export const user_missing_info = () => (dispatch) => {
  dispatch({
    type: USER_MISSING_INFO
  });
};

export const user_missing_info_turn_off = () => (dispatch) => {
  dispatch({
    type: USER_MISSING_INFO_TURN_OFF
  });
};

export const reset_go_to_next = () => (dispatch) => {
  dispatch({
    type: RESET_GO_TO_NEXT
  });
};

export const sendUserData = (data, user_id) => async (dispatch, getState) => {
  //dispatch({ type: FORM_LOADING });
  dispatch({ type: FORM_LOADING_DATA });

  await axios
    .patch(`${process.env.REACT_APP_API_URL}users/${user_id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: SENDING_DATA });
      // console.log("Done")
      dispatch({ type: FORM_LOADED_DATA });
      dispatch({ type: GO_TO_NEXT });
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED_DATA });
      dispatch(returnError(e.response.data, e.response.status));
      console.log(e);
    });
};

export const sendUserData_pref = (data, user_id) => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  await axios
    .patch(`${process.env.REACT_APP_API_URL}users/${user_id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log(e);
    });
};

export const sendUserData_account = (data, user_id) => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
  // dispatch({ type: FORM_LOADING_DATA });

  console.log("Setting account type: Signing up as a provider......")
  await axios
    .patch(`${process.env.REACT_APP_API_URL}users/${user_id}/`, data, tokenConfig(getState))
    .then((res) => {
      // dispatch({ type: SENDING_DATA });
      if (res.data) {
        // dispatch({ type: FORM_LOADED });
        console.log("Finished Setting account type: Testing providers......")
        dispatch({ type: GO_TO_WIZARD_SOCIAL });
      }
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log(e);
    });
};

export const deactivateUser = (id) => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  await axios
  .get(`${process.env.REACT_APP_API_URL}users/${id}/deactivate`, tokenConfig(getState))
  .then((res) => {
    if (res.data) {
      dispatch({ type: FORM_LOADED })
      dispatch({ type: ACCOUNT_DEACTIVATED })
      console.log('User deacativated', res.data)
    }
  })
  .catch((e) => {
    dispatch({ type: FORM_LOADED })
    console.log(e);
  });
};

export const pet_preference = (data) => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  await axios
    .post(`${process.env.REACT_APP_API_URL}pet-preferences/`, data, tokenConfig(getState))
    .then((res) => {
      if (res.data) {
        console.log(res.data);
        dispatch({ type: FORM_LOADED });
      }
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log(e);
    });
};

export const pet_preference_del = (id) => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  await axios
    .delete(`${process.env.REACT_APP_API_URL}pet-preferences/${id}/`, tokenConfig(getState))
    .then((res) => {
      if (res.data) {
        dispatch({ type: FORM_LOADED });
      }
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log(e);
    });
};

export const sendUserData_photo = (data, user_id) => async (dispatch, getState) => {
  dispatch({ type: PHOTO_LOADING });
  await axios
    .patch(`${process.env.REACT_APP_API_URL}users/${user_id}/`, data, tokenConfig(getState))
    .then((res) => {
      console.log('photo uploaded');
      dispatch({ type: PHOTO_UPLOADED });
    })
    .catch((e) => {
      console.log("e.response");
      console.log(e.response);
      dispatch({ type: PHOTO_UPLOADED });
      dispatch(returnError("We're sorry photo upload failed, please try again.", e.response.status));
      dispatch(createMessage_status("We're sorry photo upload failed, please try again.", null));
    });
};

export const sendProvider_photo = (data) => async (dispatch, getState) => {
  dispatch({ type: PHOTO_LOADING });
  await axios
    .post(`${process.env.REACT_APP_API_URL}photos/`, data, tokenConfig(getState))
    .then((res) => {
      console.log('provider photo uploaded');
      dispatch(createMessage('Photo has been uploaded!'));
      dispatch({ type: PHOTO_UPLOADED });
    })
    .catch((e) => {
      console.log("e.response.data");
      dispatch({ type: PHOTO_UPLOADED });
      console.log(e.response.data);
      dispatch(returnError("We're sorry photo upload failed, please try again.", e.response.status));
      dispatch(createMessage_status("We're sorry photo upload failed, please try again.", null)); 
    });
};

export const getProvider_photo = () => (dispatch, getState) => {
  // dispatch({ type: FORM_LOADING });
  axios
    .get(`${process.env.REACT_APP_API_URL}photos/`, tokenConfig(getState))
    .then((res) => {
      // console.log(res.data.results);
      if (res.data.results) {
        dispatch({
          type: PHOTOS_LOADED,
          payload: res.data.results
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const deleteProvider_photo = (id) => (dispatch, getState) => {
  dispatch({ type: DELETE_PHOTO_LOADING });
  axios
    .delete(`${process.env.REACT_APP_API_URL}photos/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: PHOTOS_DELETED
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({
        type: PHOTOS_DELETED
      });
    });
};

export const getServiceData = (user_id) => async (dispatch, getState) => {
  dispatch({ type: SERVICE_LOADING });

  await axios
    .get(`${process.env.REACT_APP_API_URL}users/${user_id}/services/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SERVICE_LOADED,
        payload: res.data
      });
      dispatch({ type: GETTING_DONE });
    })
    .catch((e) => {
      dispatch({ type: SERVICE_LOADED });
      console.log(e);
    });
};

export const getAdditional_RatesData = (url) => async (dispatch, getState) => {
  if(url){
    await axios
    .get(`${url}`, tokenConfig(getState))
    .then((res) => {
        dispatch({
          type: ADDITIONAL_RATES_LOADED,
          payload: res.data
        });
        dispatch({ type: CREATED_ADDITIONAL });
      })
    .catch((e) => {
        dispatch({ type: CREATED_ADDITIONAL });
        console.log(e);
      });
  }else{
      await axios
      .get(`${process.env.REACT_APP_API_URL}special-rates/`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: ADDITIONAL_RATES_LOADED,
          payload: res.data
        });
        dispatch({ type: CREATED_ADDITIONAL });
      })
      .catch((e) => {
        dispatch({ type: CREATED_ADDITIONAL });
        console.log(e);
      });
  }
};


export const get_all_services = () => (dispatch, getState) => {
  dispatch({ type: SERVICE_LOADING });

  axios
    .get(`${process.env.REACT_APP_API_URL}services/`, tokenConfig(getState))
    .then((res) => {
      // console.log(res.data)
      // console.log(res.data.result)
      const filteredRes = res.data.result.filter(item => item.name !== "Background Check");
      dispatch({
        type: ALL_SERVICE_LOADED,
        payload: filteredRes
      });
    })
    .catch((e) => {
      //dispatch({ type: SERVICE_LOADED });
      console.log(e);
    });
};

export const get_addtional_rate_types = () => (dispatch, getState) => {
  // dispatch({ type: SERVICE_LOADING });

  axios
    .get(`${process.env.REACT_APP_API_URL}additional-rate-types/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ADDITIONAL_RATES_TYPES,
        payload: res.data.results
      });
    })
    .catch((e) => {
      //dispatch({ type: SERVICE_LOADED });
      console.log(e);
    });
};

export const get_all_rates = () => (dispatch, getState) => {
  dispatch({ type: SERVICE_LOADING });

  axios
    .get(`${process.env.REACT_APP_API_URL}service-rates`)
    .then((res) => {
      dispatch({
        type: ALL_RATES_LOADED,
        payload: res.data.results
      });
    })
    .catch((e) => {
      //dispatch({ type: SERVICE_LOADED });
      console.log(e);
    });
};

export const getPetTypes = () => async (dispatch, getState) => {
  dispatch({ type: SERVICE_LOADING });

  await axios
    .get(`${process.env.REACT_APP_API_URL}pet-types`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GOT_PET_TYPES,
        payload: res.data.results
      });
    });
};

export const user_create_pet =
  (name, gender, date_of_birth, color, breed, size, pet_type, owner) => (dispatch, getState) => {
    dispatch({ type: CREATE });
    dispatch({ type: PETS_LOADING });

    // const age = parseFloat(age_);
    const body = JSON.stringify({
      name,
      gender,
      date_of_birth,
      color,
      breed,
      size,
      pet_type,
      owner
    });
    axios
      .post(`${process.env.REACT_APP_API_URL}pets/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: USER_CREATE_PET,
          payload: res.data
        });
        dispatch({ type: PETS_LOADING });
        console.log(res.data)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log(err);
        }
      });
  };

export const user_update_pet =
  (name, gender, age_, color, breed, size, pet_type, owner, id) => (dispatch, getState) => {
    dispatch({ type: FORM_LOADING });
    dispatch({ type: FORM_PET_UPDATE_LOADING });
    const age = parseFloat(age_);
    const body = JSON.stringify({
      name,
      gender,
      age,
      color,
      breed,
      size,
      pet_type,
      owner
    });
    // console.log(body);
    axios
      .put(`${process.env.REACT_APP_API_URL}pets/${id}/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: FORM_LOADED });
      })
      .catch((err) => {
        dispatch({ type: FORM_LOADED });
        if (err.response) {
          console.log(err.response);
        } else {
          console.log(err);
        }
      });
  };

export const user_create_service =
  (description, price_, user, service, rate) => (dispatch, getState) => {
    dispatch({ type: CREATE });
    const price = parseFloat(price_);
    const body = JSON.stringify({
      description,
      price,
      user,
      service,
      rate
    });
    axios
      .post(`${process.env.REACT_APP_API_URL}user-services/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: CREATE_SERVICE,
          payload: res.data
        });
      })
      .catch((e) => {
        console.log(e.response.data);

        if(e.response.data){
          if(e.response.data.service){
            dispatch(createMessage_status("Service already exists.", null)); 
          }else if(e.response.data?.description){
            if(e.response.data?.description){
              dispatch(createMessage_status(e.response.data?.description, null)); 
            }
            if(e.response.data?.description?.bio){
              dispatch(createMessage_status(e.response.data?.description?.bio, null)); 
            }
          }
        }
      });
  };

export const user_create_additional_rate =
  (price_, service, provider, rate_type) => (dispatch, getState) => {
    dispatch({ type: CREATE_ADDITIONAL });
    const price = parseFloat(price_);
    const body = JSON.stringify({
      provider,
      price,
      rate_type,
      service
    });
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_URL}additional-rates/`, body, tokenConfig(getState))
      .then((res) => {
        console.log(res.data);
        // dispatch({
        //   type: CREATED_ADDITIONAL,
        // });
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const user_update_additional_rate =
  (price, service, provider, rate_type, id) => (dispatch, getState) => {
    dispatch({ type: FORM_LOADING });
    // const price = parseFloat(price_);
    const body = JSON.stringify({
      provider,
      price,
      rate_type,
      service
    });
    console.log(body);
    axios
      .patch(`${process.env.REACT_APP_API_URL}additional-rates/${id}/`, body, tokenConfig(getState))
      .then((res) => {
        console.log(res.data);
        dispatch({ type: FORM_LOADED });
      })
      .catch((e) => {
        dispatch({ type: FORM_LOADED });
        console.log(e);
      });
  };
// {price, service_id, user_id, rate_id, additional_rate_id
//   "price": 0,
//   "service": 0,
//   "provider": 0,
//   "rate_type": 0,
//   "occurrence": 0
// }

export const user_update_service =
  (description, price_, user, service, rate, user_service_id) => (dispatch, getState) => {
    dispatch({ type: FORM_LOADING });
    const price = parseFloat(price_);
    const body = JSON.stringify({
      description,
      price,
      user,
      service,
      rate
    });
    // console.log(body)
    axios
      .put(
        `${process.env.REACT_APP_API_URL}user-services/${user_service_id}/`,
        body,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: FORM_LOADED });
      })
      .catch((e) => {
        dispatch({ type: FORM_LOADED });
        console.log(e);
        
        console.log(e.response.data);
        if(e.response.data){
          if(e.response.data.service){
            dispatch(createMessage_status("Service already exists.", null)); 
          }
          if(e.response.data?.description){
            if(e.response.data?.description){
              dispatch(createMessage_status(e.response.data?.description, null)); 
            }
            if(e.response.data?.description?.bio){
              dispatch(createMessage_status(e.response.data?.description?.bio, null)); 
            }
          }
        }

      });
  };

export const create_preference =
  (weight_preference, pet_provider, pet_type) => (dispatch, getState) => {
    dispatch({ type: FORM_LOADING });

    const body = JSON.stringify({
      weight_preference,
      pet_provider,
      pet_type
    });
    // console.log(body)
    axios
      .post(`${process.env.REACT_APP_API_URL}pet-preferences/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: FORM_LOADED });
        console.log('pet preferences saved...');
      })
      .catch((e) => {
        dispatch({ type: FORM_LOADED });
        console.log(e);
      });
  };

export const user_delete_service = (id) => async (dispatch, getState) => {
  dispatch({ type: SERVICE_LOADING });
  dispatch({ type: FORM_LOADING });

  await axios
    .delete(`${process.env.REACT_APP_API_URL}user-services/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: SERVICE_DELETED, payload: id  });
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      //dispatch({ type: SERVICE_LOADED });
      console.log('Deleted');
    });
};

export const user_delete_rate = (id) => async (dispatch, getState) => {
  dispatch({ type: SERVICE_LOADING });
  dispatch({ type: FORM_LOADING });

  await axios
    .delete(`${process.env.REACT_APP_API_URL}special-rates/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: SERVICE_DELETED, payload: id });
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      //dispatch({ type: SERVICE_LOADED });
      console.log('Deleted');
    });
};

export const user_delete_pet = (id) => async (dispatch, getState) => {
  dispatch({ type: SERVICE_LOADING });
  dispatch({ type: FORM_LOADING });

  await axios
    .delete(`${process.env.REACT_APP_API_URL}pets/${id}/`, tokenConfig(getState))
    .then(() => {
      console.log('Deleted');
      dispatch({ type: FORM_LOADED });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FORM_LOADED });
    });
};

export const sendServiceData = (data, user_id) => async (dispatch, getState) => {
  await axios
    .patch(
      `${process.env.REACT_APP_API_URL}users/${user_id}/services/`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      console.log(res);
    })
    .catch((e) => {
      console.log(e);
    });
};
export const clear_state = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE_
  });
};

export const clear_state_payment = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE_PAYMENT
  });
};

export const clear_state_pets = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE_PET
  });
};

export const init_fetch = () => (dispatch) => {
  dispatch({
    type: FETCH_
  });
};
export const deinit_fetch = () => (dispatch) => {
  dispatch({
    type: FETCH_DONE_
  });
};

export const getPetData = (user_id) => async (dispatch, getState) => {
  dispatch({ type: PETS_LOADING });

  await axios
    .get(`${process.env.REACT_APP_API_URL}users/${user_id}/pets/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: PETS_LOADED,
        payload: res.data
      });
      dispatch({ type: GETTING_DONE });
    })
    .catch((e) => {
      dispatch({ type: PETS_LOADED });
    });
};

export const getCertificate = () => (dispatch, getState) => {
  dispatch({ type: CERTIFICATE_LOADING });
  dispatch({ type: FORM_LOADING });
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`
    }
  };

  axios
    .get(`${process.env.REACT_APP_API_URL}certificates/`, config)
    .then((res) => {
      dispatch({ type: FORM_LOADED });
      dispatch({
        type: CERTIFICATE_LOADED,
        payload: res.data
      });
    })
    .catch((e) => {
      console.log(e);
      // dispatch(returnError(e.response.data, e.response.status));
      dispatch({ type: FORM_LOADED });
    });
};

export const sendCertificate = (file) => (dispatch, getState) => {
  const token = getState().auth.token;
  dispatch({ type: SENDING_CERTIFICATE });
  let data = new FormData();
  let name = 'certificate';
  data.append(name, file, file.fileName);
  axios
    .post(`${process.env.REACT_APP_API_URL}certificates/`, data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        Authorization: `JWT ${token}`
      }
    })
    .then((response) => {
      //handle success
      dispatch({ type: SENT_CERTIFICATE });
      console.log(response);
    })
    .catch((error) => {
      //handle error
      dispatch({ type: SENT_CERTIFICATE });
      console.log(error);
    });
};

export const sendPetData = (data) => async (dispatch, getState) => {
  dispatch({ type: PETS_LOADING });
  dispatch({ type: FORM_LOADING });
  // console.log(data);
  await axios
    .post(`${process.env.REACT_APP_API_URL}pets/`, data, tokenConfig(getState))
    .then((res) => {
      //console.log(res);
      dispatch({ type: PETS_LOADED });
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: FORM_LOADED });
    });
};

export const get_breeds = () => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
  await axios
    .get('https://api.thedogapi.com/v1/breeds')
    .then((res) => {
      const list = [];
      // eslint-disable-next-line no-unused-vars
      const check = res.data.filter((breed) => {
        list.push(breed.name);
      });
      // console.log(list)
      dispatch({
        type: GET_BREEDS,
        payload: list
      });
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: FORM_LOADED });
    });
};

export const get_breeds_cats = () => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
  await axios
    .get('https://api.thecatapi.com/v1/breeds')
    .then((res) => {
      const list = [];
      // eslint-disable-next-line no-unused-vars
      const check = res.data.filter((breed) => {
        list.push(breed.name);
      });
      // console.log(list)
      dispatch({
        type: GET_BREEDS_CAT,
        payload: list
      });
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: FORM_LOADED });
    });
};

export const updatePetData = (data, id) => async (dispatch, getState) => {
  dispatch({ type: PETS_LOADING });
  dispatch({ type: FORM_LOADING });

  await axios
    .patch(`${process.env.REACT_APP_API_URL}pets/${id}/`, data, tokenConfig(getState))
    .then(() => {
      dispatch({ type: PETS_LOADED });
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: FORM_LOADED });
    });
};

export const deletePetData = (id) => async (dispatch, getState) => {
  dispatch({ type: PETS_LOADING });
  dispatch({ type: FORM_LOADING });

  await axios
    .delete(`${process.env.REACT_APP_API_URL}pets/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: FORM_LOADED });
      dispatch({ type: PET_DELETED });
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log('Deleted');
    });
};

export const deleteCertificate = (id) => (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });
  axios
    .delete(`${process.env.REACT_APP_API_URL}certificates/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log('Deleted');
    });
};

export const connectFacebook = (token) => (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  // eslint-disable-next-line no-unused-vars
  const body = {
    access_token: token
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}auth/facebook/connect/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: FORM_LOADED });
      // console.log(res);
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log(e);
    });
};

export const change_password = (old_password, new_password) => (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  const body = JSON.stringify({
    old_password,
    new_password
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}auth/password/change/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: FORM_LOADED });
      console.log(res.data);
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log(e);
    });
};

export const connectGoogle = () => (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  axios
    .post(`${process.env.REACT_APP_API_URL}auth/google/connect/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: FORM_LOADED });
      //console.log(res);
    })
    .catch((e) => {
      dispatch({ type: FORM_LOADED });
      console.log(e);
    });
};

export const createCreditCard = (card) => (dispatch, getState) => {
  dispatch({ type: CARD_LOADING });

  axios
    .post(`${process.env.REACT_APP_API_URL}credit-cards/`, card, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: CARD_CREATED_SUCCESS, payload: res.data });
      // console.log(res);
    })
    .catch((e) => {
      dispatch({ type: CARD_CREATED_FAIL });
      console.log(e);
    });
};

// CREATE PAYMENT METHODS
export const create_payment_method =
  (card_number, exp_month, exp_year, cvc, first_name, last_name) => (dispatch, getState) => {
    dispatch({ type: FORM_LOADING_CREATE });

    // Request Body
    const body = JSON.stringify({ card_number, exp_month, exp_year, cvc , first_name, last_name});

    axios
      .post(`${process.env.REACT_APP_API_URL}payment-methods/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: FORM_LOADED });
        const card_created = true
        localStorage.setItem("card_created", card_created)
        dispatch({
          type: CREATE_PAYMENT_METHOD,
          payload: res.data
        });
      })
      .catch((err) => {
        if (err) {
          console.log(err.response);
          dispatch(returnError(err.response.data, null));
          dispatch({ type: FORM_LOADED });
        }
      });
  };

// CREATE PAYMENT METHODS
export const create_payment_method_first =
  (card_number, exp_month, exp_year, cvc, first_name, last_name) => (dispatch, getState) => {
    dispatch({ type: FORM_LOADING_CREATE });

    // Request Body
    const body = JSON.stringify({ card_number, exp_month, exp_year, cvc, first_name, last_name });

    axios
      .post(`${process.env.REACT_APP_API_URL}payment-methods/`, body, tokenConfig(getState))
      .then((res) => {
        if (res.data) {

          const card_id = res.data.id;
          const is_primary = true;
          const body_ = JSON.stringify({ is_primary });
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}payment-methods/${card_id}/`,
              body_,
              tokenConfig(getState)
            )
            .then((res) => {
              localStorage.setItem("owner_primary_card", card_id)
              dispatch({
                type: CREATE_PAYMENT_METHOD,
                payload: res.data
              });
              
            })
            .catch((err) => {
              if (err) {
                console.log(err);
                dispatch({ type: FORM_LOADED });
              }
            });
        }
        dispatch({ type: FORM_LOADED });
        const card_created = true
        localStorage.setItem("card_created", card_created)
      })
      .catch((err) => {
        if (err) {
          console.log(err.response);
          dispatch(returnError(err.response.data, null));
          dispatch({ type: FORM_LOADED });
        }
      });
  };

// SET PRIMARY PAYMENT METHOD
export const set_primary = (id, is_primary) => (dispatch, getState) => {
  dispatch({ type: MAKE_PRIMARY_LOADING });
  dispatch({ type: FORM_LOADING });

  // Request Body
  const body = JSON.stringify({ is_primary });
  // console.log(body)

  axios
    .patch(`${process.env.REACT_APP_API_URL}payment-methods/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: MAKE_PRIMARY });
      dispatch({ type: FORM_LOADED });
      if(is_primary === true){
        localStorage.setItem("owner_primary_card", id)
      }
      dispatch(createMessage('Card status changed and saved.'));
    })
    .catch((err) => {
      if (err) {
        console.log(err.response);
        dispatch({ type: MAKE_PRIMARY_FAILED });
        dispatch({ type: FORM_LOADED });
      }
    });
};

// GET PAYMENT METHODS
export const get_payment_method = () => (dispatch, getState) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}payment-methods/`, tokenConfig(getState))
    .then((res) => {
      console.log("got payments data:")
      console.log(res.data)
      dispatch({
        type: GET_PAYMENT_METHOD,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};

// DELETE PAYMENT METHOD
export const delete_payment_method = (id) => async (dispatch, getState) => {
  dispatch({ type: FORM_LOADING });

  await axios
    .delete(
      `${process.env.REACT_APP_API_URL}payment-methods/${id}/`,
      //`${process.env.REACT_APP_API_URL}payment-methods/?id=${id}`,
      tokenConfig(getState)
    )
    .then(() => {
      dispatch({ type: PAYMENT_METHOD_DELETED });
      //  dispatch( createMessage("Card Deleted Successfully."));
      dispatch({ type: FORM_LOADED });
    })
    .catch((e) => {
      console.log('Deleting');
      // console.log(e.response.data);
      dispatch(returnError(e.response.data, null));
      dispatch({ type: FORM_LOADED });
      dispatch({ type: FORM_LOADED });
    });
};


